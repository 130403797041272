<template>
  <div class="main">
  <strong>常见问题</strong>
  <div>
  <span>1、全球数据卡是什么？</span>
  <p>全球数据卡是中国移动国际公司面向全球商旅客户推出的流量上网卡。<br>
  全球数据卡产品包括两种形态，一是实体SIM卡，卡片为形态为三合一卡（标准卡，Micro卡，Nano卡）；二是eSIM形态。<br>
  <br>
  </p>
  </div>
  <div>
  <span>2、全球卡有语音短信功能吗？</span>
  <p>只有上网功能，无语音及主叫短信功能。<br>
  <br>
  </p>
  </div>
  <div>
  <span>3、产品覆盖包括哪些区域？</span>
  <p>截至2022年4月，全球数据卡覆盖方向（含附属岛屿及海外属地）达187个，该覆盖会持续增加。国际公司会根据全球运营商合作情况，动态调整。覆盖方向及每个方向的运营商信息，可在全球卡官网查询，或向客服咨询、或向销售代表获取最新覆盖。<br>
  <br>
  </p>
  </div>
  <div>
  <span>4、产品是否可以访问谷歌地图等应用？</span>
  <p>全球数据卡可在全球各地访问谷歌、Facebook等应用。<br>
  <br>
  </p>
  </div>
  <div>
  <span>5、产品适用哪些类型的终端？</span>
  <p>全球数据卡适用于支持OTA标准的终端，包括：绝大多数的手机（极少数非常老旧的手机不支持）、苹果支持Cellular的iPAD、部分MiFi设备（需要测试，大部分MiFi不支持。已测试通过的型号为华为随行MiFi，型号E5885、E5577Bs-937）。<br>
  <br>
  </p>
  </div>
  <div>
  <span>6、关于产品有效期？</span>
  <p>(1) SIM卡的有效期是2年，每次使用都会顺延2年。<br>
  (2) 套餐需在购买后180天内激活，否则套餐会过期。<br>
  (3) 套餐激活后，有效期依据产品产品设定。
  <br>
  </p>
  </div>
  <div>
  <span>7、抵达当地后需要怎样才能连接网络？</span>
  <p>到达目的地后，将SIM卡插入手机等待数分钟，手机会自动选择网络。正常而言无须客户手动设置，但部分情况下如果长时间未登上网，需客户手动选网。<br>
  <br>
  </p>
  </div>
  <div>
  <span>8、是否需要手动激活上网？</span>
  <p>全球数据卡上网模式默认为自动模式，即用户只要到达目的地后，将卡插入手机后即可自动激活。但由于全球卡有多地区套餐，为了方便购买了多地区套餐的用户，提供了手动激活的模式供客户选择（例如，某个香港用户购买了全球多地区套餐希望在香港以外地区使用，但在自动模式下，用户在香港将卡插入手机即会激活套餐）。在用户选择手动激活模式后，需要用户到达当地后，通过登录APP或官网手动激活套餐才能上网。<br>
  <br>
  默认情况下，全球卡无须手动激活，只要客户到达适用方向后开启上网功能（打开数据开关、漫游开关）后上网会自动激活。<br>
  <br>
  </p>
  </div>
  <div>
  <span>9、激活后天数如何计算？</span>
  <p>全球卡1天的定义是激活后的24小时计算为1天。例如，用户2019年1月1日07:38到香港开始上网，其香港1天套餐被激活，用户可在香港上网至2019年1月2日07：37。<br>
  <br>
  </p>
  </div>
  <div>
  <span>10、激活后每天上网是否会被限速？</span>
  <p>全球卡有两类产品，一是限速不限量产品；二是定量产品。对于前者，每天有一定的高速流量，高速流量（例如每天500MB）用完后会受公平使用政策限速，网络速度会被限制到768kbps（上下行各384kbps）；高速流量会每24小时进行重设，重设后用户可恢复高速上网。<br>
  <br>
  </p>
  </div>
  <div>
  <span>11、激活24小时内从一个国家到另一个国家怎么样？</span>
  <p>全球卡套餐激活后24小时内客户从一个国家到另一个国家，如果该国家在套餐覆盖范围内，则会自动继续使用套餐；如果该国家不在套餐覆盖范围内，则无法上网。<br>
  <br>
  </p>
  </div>
  <div>
  <span>12、如果需要手动配置APN，怎么设置？</span>
  <p>【iPhone】<br>
  (1) 打开[设置] &gt; [蜂窝移动网络] <br>
  (2) 打开[蜂窝移动数据选项] <br>
  (3) 打开[蜂窝移动数据网络] : 请参考产品业务规范中的网络运营商列表输入[APN] <br>
  (4) 开啓 [数据漫游]<br>
  <br>
  【Android】<br>
  (1) 打开[设置] &gt; [移动网络] <br>
  (2) 打开[接入点名称 (APN)] &gt;[新建APN] : 请参考产品业务规范中的网络运营商列表输入[APN]<br>
  (3) 开啓 [数据漫游] &gt;点击 [保存]<br>
  (因手机及系统版本不同，显示名称及步骤会略有不同)<br>
  <br>
  </p>
  </div>
  <div>
  <span>13、关于只能上微信问题：客户只能使用微信，无法使用其它应用或上网</span>
  <p>1 请客户检查手机APN设置下是不是填了代理服务器？如果有代理服务器，请把服务器删除（正常不应有代理服务器）<br>
  2 手机有没有VPN软件，如果有也会影响上网<br>
  3 手机是否有设置各应用上网权限（如对部分应用设置了只有WIFI网络才能上网）<br>
  <br>
  </p>
  </div>
  <div>
  <span>14、关于双卡手机问题：客户插卡后只显示2G信号</span>
  <p>请客户检查是否把卡插到了3/4G上网卡槽。客户必须把卡插在3/4G卡槽，或者把3/4G上网功能切换到插全球卡的卡槽。<br>
  <br>
  </p>
  </div>
  <div>
  <span>15、如果到了全球卡不支持的方向会怎么样？</span>
  <p>如果用户到了全球数据卡暂时未覆盖的方向，则无法上网。<br>
  <br>
  </p>
  </div>
  <div>
  <span>16、是否支持手机热点</span>
  <p>全球卡支持将手机作热点分享给其它手机使用。但在热点模式下，每个用户的网速将低于不分享的情况，且每天的高速流量将会较快消耗完。<br>
  <br>
  </p>
  </div>
  <div>
  <span>17、关于无SIM卡问题</span>
  <p>由于全球卡卡片为三合一卡片，若将SIM卡从卡板上掰下来时用力过猛或不当，可能存在将SIM卡损坏的情况。若遇到插卡后手机提示无SIM卡，请按以下方式操作：<br>
  1 重新插卡后重启手机尝试<br>
  2 换机测试，确认问题是否仍存在<br>
  3 如果仍无法识别SIM，应为SIM卡损坏，请联系客服。<br>
  <br>
  </p>
  </div>
  <div>
  <span>18、手机无服务问题</span>
  <p>手机无服务指手机插卡后提示无服务（不是无SIM卡。无SIM卡指手机从物理上没有检测到SIM卡，无服务指有SIM卡但手机未附着上网络），请按以下步骤处理：<br>
  1 请用户手动再选网，检查选网界面出现哪些运营商选项，选择合适运营商；<br>
  2 若手机一直未出现合适运营商，请确认用户所处位置的覆盖，或者换机测试；<br>
  3 若用户换位置或换手机后仍提示无服务，请联系客服。<br>
  <br>
  </p>
  </div>
  <div>
  <span>19、手机无法选网问题</span>
  <p>手机无法选网，指用户手机搜索到了网络信号，但网络名称后面提示（禁用），或者选择以后提示注册网络失败。这种情况有是手机由于某种原因在首次附着网络出现失败后，手机启动了记忆模式，将该网络标注为禁用。建议多尝试几次，即便手机提示禁用的网络，只要是全球卡适用的网络运营商，均可手动选择附着。
  <br>
  <br>
  </p>
  </div>
  <div>
  <span>20、无法激活问题</span>
  <p>只要手机插卡后有上报位置信息，套餐即可激活。若套餐无法激活，应为手机未上报位置信息。可能的原因：<br>
  1 手机未上报任何位置信息，如SIM卡损坏、手机无服务等均会导致套餐无法激活<br>
  2 手机自带的国际上网服务（如华为手机的天际通、小米的全球上网、VIVO的i漫游）<br>
  3 用户在网站上设置了手动激活<br>
  4 用户通过SIM卡工作，将SIM卡工作模式设置为手动模式<br>
  对于此类问题，排障指引：<br>
  1 手机选网是否正确<br>
  2 手机是否有开启自带国际上网服务<br>
  3 请登录网站查询套餐激活模式，或向客服咨询。<br>
  4 检查用户SIM卡工具工作模式是否为自动（详见SIM卡工具使用）<br>
  <br>
  </p>
  </div>
  <div>
  <span>21、无法上网问题</span>
  <p>无法上网指用户套餐激活后，无法访问网络。可能的原因及应对如下：<br>
  1 未开数据漫游开关、数据上网开关（检查用户配置截图）<br>
  2 APN设置错误（检查用户APN，如果错误需手动更正）<br>
  3 双卡手机用户将卡插在未开启数据的卡槽（换卡槽或者将数据功能移至插了全球卡的卡槽）<br>
  4 如以上问题均已排除，请联系客服<br>
  <br>
  </p>
  </div>
  <div>
  <span>22、上网速度慢的问题</span>
  <p>上网速度慢，由于为主观感觉，如果用户无具体描述，则较难进行分析定位。建议用户提供详细描述，如测速结果（部分安卓手机会显示手机实时网速，或通过测速软件进行测速）、手机应用慢的截屏（如微信发消息时在转圈，或网页加载慢）等。可能造成网络速度慢的原因及应对包括：<br>
  1 用户所处位置只有2/3G网络覆盖，无4G信号覆盖（查询覆盖运营商表是否只支持3G，或询问用户手机是否可以搜索到4G网络）<br>
  2 用户手机未打开4G开关（让用户提供截图，看是否有打开4G上网开关）<br>
  3 用户当天用量已超出限速阀值，导致被限速，请咨询客服查询当前数据用量。<br>
  45 用户所处位置信号较弱（可通过手机信号显示）或者用户所处位置人群特别密集（如地铁站、高铁站、体育场馆、步行街等人流聚集地）<br>
  <br>
  </p>
  </div>
  <div>
  <span>25、eSIM不能分享热点的问题</span>
  <p>由于不同手机终端版本的问题，对于遇到eSIM能正常使用但不能分享热点，请根据以下指引尝试是否能恢复：<br>
  1 手机是否合约机或锁机<br>
  2 是否已关闭VPN<br>
  3 是否已开启数据漫游<br>
  4 是否将eSIM设置为主号<br>
  5 手机是否更新到最新的iOS版本<br>
  6 请使用一张实体卡上网，打开手机热点分享功能（确保热点可用），然后不要关闭热点，再把手机切换到eSIM上网。请反复尝试几次及开关机。<br>
  7 如以上问题均已排除，请联系客服<br>
  <br>
  </p>
  </div>
  <div>
  <span>香港实名制</span>
  <p>
  <b>1. 所有全球数据卡的用户都需要完成实名登记才能使用吗? </b>
  <br>
  由2022年3月1日起，所有全球数据卡用户在香港地区使用数据服务前，需要完成实名登记，未完成实名登记的用户不能激活套餐使用。
  客户可于官网 <a href="https://global.cmlink.com/store/realname">https://global.cmlink.com/store/realname</a> 完成的实名登记。<br>
  <br>

  <b>2. 实名认证接受什么身份证明文件类型?</b>
  <br>
  a. 护照 (不包括香港特区护照及英国国民（海外）护照)<br>
  b. 香港身份证<br>
  c. 港澳通行证<br>
  d. 澳门身份证<br>
  注意﹕如客户有香港身份证的话必需要使用香港身份证做登记。<br>
  <br>

  <b>3. 实名登记需要什么资料?</b>
  <br>
  a. 中文姓名（如适用）及英文姓名<br>
  b. 出生日期 (用户必须为16岁以上的人士)<br>
  c. 身份证明文件号码 （如申请人并不持有香港身份证，他/她须作出以下声明(i)不持有香港身份证及(ii)没有使用其他身份证明文件作登记）<br>
  d. 身份证明文件副本<br>
  <br>

  <b>4. 如何上载我的身份证明文件以进行实名登记?</b>
  <br>
  客户可于官网 <a href="https://global.cmlink.com/store/realname">https://global.cmlink.com/store/realname</a> 完成的实名登记。你可用具相机功能的平板计算机或智能手机拍摄你的身份证明文件正本，或上传该身份证明文件的图像档案至网站。注意不支持黑白图像的上传。<br>
  <br>

  <b>5. 每个用户可以登记多少张全球数据卡?</b>
  <br>
  每个用户可以登记不多于10张。<br>
  <br>

  <b>6. 如何得知实名登记是否成功完成?</b>
  <br>
  成功完成登记后，我们会向用户发出短讯或邮件通知。<br>
  实名审核不通过也会通过短讯或邮件通知你。<br>
  如你一直未能收到有关确认成功登记通知，你可直接联络客服作进一步查询。<br>
  <br>
  </p>
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FAQZhView'
})
</script>

<style scoped>
.main {
    width: 100%;
    padding: 5px;
    text-align: left;
  }
strong {
  font-size: 32px;
  font-weight: bold;
}
span {
  font-size: 18px;
  font-weight: bold;
  color:#8dc11e;
}
@media screen and (min-width: 600px) {
  .main {
    width: 80%;
    padding: 20px;
    text-align: left;
  }
}
</style>
