<template>
  <div class="main">
    <div>
    <strong>FAQ</strong>
    <div>
    <span>1. What is Global Data SIM?</span>
    <p>Global Data SIM is a global internet product launched by China Mobile International Limited (CMI).
    There are 2 card types for Global Data SIM, includes physical SIM and eSIM. Physical SIM is a 3-in-1 SIM card that supports nano, micro and normal formats. <br>
    <br>
    </p>
    </div>
    <div>
    <span>2. Does Global Data SIM support voice call and SMS?</span>
    <p>Global Data SIM supports data service only. It does not support voice call and SMS.<br>
    <br>
    </p>
    </div>
    <div>
    <span>3. What is the coverage of the Global Data SIM?</span>
    <p>As of Apr 2022, Global Data SIM covers over 187 destinations (including affiliated islands and overseas territories), and the coverage will keep increasing.  We will dynamically adjust the coverage according to the cooperation with global operators. Latest version of the coverage destinations and operator list can be checked on our official website, or contact our Customer service or channel partners.<br>
    <br>
    </p>
    </div>
    <div>
    <span>4. Does Global Data SIM support applications like Google Map? </span>
    <p>Global Data SIM can access applications like Google, Facebook etc.<br>
    <br>
    </p>
    </div>
    <div>
    <span>5. What type of devices does Global Data SIM support?</span>
    <p>Global Data SIM is applicable to devices which support OTA standard, including majority mobile phones (except those old models phones), Apple iPAD which supports cellular, and some MiFi devices (requires individual testing. Models with positive result includes Huawei MiFi E5885, E5577Bs-937).<br>
    <br>
    </p>
    </div>
    <div>
    <span>6. What is the expiry of Global Data SIM?</span>
    <p>(1) The validity period of the SIM card is 2 years, and each use will be extended by 2 years. <br>
    (2) The package must be activated within 180 days after purchase, otherwise the package will expire. <br>
    (3) After package activation, the validity period is determined by the product settings.
    <br>
    </p>
    </div>
    <div>
    <span>7. How do I access internet after arrived destination?</span>
    <p>After arrived destination, please insert SIM in mobile phone and wait for a few minutes, the network will be selected automatically. Normally you don’t have to do manual setup, but please manual select the operator if you can’t access internet.<br>
    <br>
    </p>
    </div>
    <div>
    <span>8. Do I need to manual activate the SIM?</span>
    <p>The default activation mode is auto. That means when you arrived destination, the data package can be activated after you insert the SIM in phone. However, since there are Multi regional data packages, in order to facilitate customers who purchased these packages, we provide the manual activation mode option for customers. (For example, a customer in Hong Kong purchased Multi regional package and wants to use outside Hong Kong, the data package will be activated when customer inserts the SIM in phone under auto mode.) If the customer choose manual activation mode, customer has to access official website to manual activate data package after arrived the destination.<br>
    <br>
    By default, Global Data SIM does not need to manual activate, you just need to switch on the roaming function and the data package will be activated automatically.<br>
    <br>
    </p>
    </div>
    <div>
    <span>9. How to calculate the data usage time after activated?</span>
    <p>Package will be counting continuously once activated. Every 24 hours will be counted as 1 day. If the customer arrived Hong Kong and use data starting from 1 January 2019 07:38, and the Hong Kong 1 day data package is activated. The customer can access internet in Hong Kong until 2 January 2019 07:37.<br>
    <br>
    </p>
    </div>
    <div>
    <span>10. Is there any daily speed limitation after data package activated?</span>
    <p>There are 2 types of Global Data SIM product, limited speed unlimited data product and fixed data product. For limited speed unlimited data product, there is 500MB daily high speed data usage, thereafter unlimited restricted speed data usage with speed 768kbps (384kbps for upstream and downstream). High speed data will be reset every 24 hours. Customer can resume high speed internet access.<br>
    <br>
    </p>
    </div>
    <div>
    <span>11. How about if I am going from 1 destination to another within 24 hours after activated?</span>
    <p>If the destination is within the package coverage, the data package will be continued automatically if you going from 1 destination to another within 24 hours. If the destination is not covered by the data package, you will not be able to access internet.<br>
    <br>
    </p>
    </div>
    <div>
    <span>12. How to manual set up APN?</span>
    <p>【iPhone】<br>
    (1) Go to [Settings]&gt;[Mobile Data] <br>
    (2) Open [Mobile Data Options] <br>
    (3) Open [Mobile Data Network] and input APN (Please refer to the network operator list <br>
    (4) Switch on [Data Roaming]<br>
    <br>
    【Android】<br>
    (1) Go to [Settings]&gt;[Mobile Network]<br>
    (2) Go to [APNs] &gt; [New APN] and input name &amp; APN (Please refer to the network operator list<br>
    (3) Switch on [Data Roaming] &gt; Click [Save]<br>
    (Display name and steps may be different depending on the phone model and OS system version)<br>
    <br>
    </p>
    </div>
    <div>
    <span>13. Why I only can access WeChat, cannot access other applications or internet?</span>
    <p>1） Check whether the “Proxy” in the APN setting is blank<br>
    2） If there is VPN software in phone, it will affect internet access<br>
    3） Check whether the phone has set the permission to access internet for other application<br>
    <br>
    </p>
    </div>
    <div>
    <span>14. Why it shows 2G network after I inserted the SIM card in phone?</span>
    <p>Please check whether the SIM is inserted in 3/4G network slot in phone.<br>
    <br>
    </p>
    </div>
    <div>
    <span>15. What happens if I go to the destination that Global Data SIM does not support?</span>
    <p>If you go to the destination that Global Data SIM does not support, you can’t access internet.<br>
    <br>
    </p>
    </div>
    <div>
    <span>16. Does it support personal hotspot?</span>
    <p>Global Data SIM supports personal hotspot sharing to other phone. However, in hotspot mode, the network speed may be lower than normal, and the daily high speed data will be consumed faster.<br>
    <br>
    </p>
    </div>
    <div>
    <span>17. Why does my phone say ‘no SIM’ or ‘invalid SIM’ after inserting SIM card?</span>
    <p>Global Data SIM is a 3-in-1 sim card, which can be used as standard, micro and nano sim. Please be gently when removing the sim card from the pack. Please follow below steps if your sim card cannot be detected by phone:<br>
    1) Restart your phone after inserting sim card;<br>
    2) Insert the sim card to another phone to retry;<br>
    3) If the problem still persists, please contact our Customer Service team.
    <br>
    <br>
    </p>
    </div>
    <div>
    <span>18. Why does my phone say ‘no Service’?</span>
    <p>Please follow below steps if your phone says ‘no Service’ after sim card is successfully detected:<br>
    1) Select Carrier manually in Network Selection Settings on phone;<br>
    2) If there is no carrier showed on network list, please insert the same sim cart to another phone to retry;<br>
    3) If the problem still persists, please contact our Customer Service team.
    <br>
    <br>
    </p>
    </div>
    <div>
    <span>19. Why does my phone say forbidden network?</span>
    <p>Forbidden network is the network which doesn’t allow your sim card to register. If you have this problem after manually selecting network, please retry selecting network.<br>
    <br>
    </p>
    </div>
    <div>
    <span>20. Why my data package cannot be activated?</span>
    <p>The data package can be successfully activated if your phone performs a location update after inserting sim card. Hera some factors that may result in activation failure:<br>
    1)  Your phone fails to perform a location update to the network operator due to sim card damage or no service;<br>
    2) Some built-in-phone global roaming service (e.g. Skytone on Huawei phone, Global Internet on Xiaomi phone, i-Roaming on VIVO phone) may prohibit your phone performing location update.<br>
    3) You have turn off ‘Automatic Activation’ when purchasing package;<br>
    4) You have set sim card mode as ‘Manual Mode’ in SIM card toolkit.<br>
    <br>
    </p>
    </div>
    <div>
    <span>21. Why I cannot connect to the network after activating data package?</span>
    <p>Please follow below steps:<br>
    1)  Ensure your data roaming and cellular data is enable in network settings;<br>
    2)  Ensure your APN settings are correct. For APN settings, please refer to our operator list.<br>
    3)  If the problem still persists, please contact our Customer Service team.
    <br>
    <br>
    </p>
    </div>
    <div>
    <span>22. Why is my mobile network so slow?</span>
    <p>Many factors can result in slow mobile network. We suggest you to provide a detailed description of mobile network when checking with our CS team, such as network speed testing result, the screenshot of loading failure, etc.
    Here are some frequent factors and solutions of slow network: <br>
    1) There is no 4G network covered in your area. Please check the Operator List for 4G coverage. <br>
    2) 4G service is disabled on phone. Please check mobile data settings and provide screenshot to our CS team.<br>
    3) Your data usage has exceeded daily High Speed Data Usage threshold. The data speed would be restricted.<br>
    4) The cellular network signal is weak at your location, especially in crowds, such as railway stations, stadium, Pedestrian Street, etc. <br>
    <br>
    </p>
    </div>
    <div>
    <span>23. How to Check Data Usage ?</span>
    <p>Please contact our CS team or add SIM card on official website to check your data usage. <br>
    <br>
    </p>
    </div>
    <div>
    <span>24. Can I use SIM Toolkit Application</span>
    <p>SIM Toolkit Application is used to trouble shooting and updating V-IMSI manually when the V-IMSI isn’t updated automatically. But the toolkit would affect other functions on phone. Please consult our customer service before using it.   <br>
    <br>
    </p>
    </div>
    <div>
    <span>25. Why I cannot share hotspot with eSIM?</span>
    <p>Due to different handset version, if you face hotspot sharing issue with your eSIM, please follow below steps:<br>
    1) Ensure your phone is not a contract or locked phone<br>
    2) Switch off the VPN<br>
    3) Switch on the data roaming<br>
    4) Set the eSIM as primary<br>
    5) Ensure the iOS latest version is installed<br>
    6) Use a physical SIM to connect to Internet, then switch on the personal hotspot function, then switch to connect Internet using eSIM. Please retry several times and restart handset.<br>
    7) If the problem still persists, please contact our Customer Service team.<br>
    <br>
    </p>
    </div>
    <div>
    <span>Hong Kong Real-name Registration</span>
    <p>
    <b>1. Do all Global Data SIM users need to complete real-name registration before usage?</b>
    <br>
    Starting from 1 Mar 2022, all Global Data SIM users must finish real-name registration before using data in Hong Kong, otherwise, the plan can’t be activated. Customer can complete the real-name registration through our website
    <a href="https://global.cmlink.com/store/realname">https://global.cmlink.com/store/realname</a> .<br>
    <br>

    <b>2. Which types of identification documents will fulfill the real-name registration requirements?</b>
    <br>
    a. Passport (British National (Overseas) (BN(O)) passport or HKSAR passport is not accepted)<br>
    b. Hong Kong Identity Card<br>
    c. Hong Kong and Macao Pass<br>
    d. Macao Identity Card<br>
    Please note: Hong Kong residents must use HKID cards for real-name registration.<br>
    <br>

    <b>3. What information is required for Real-name Registration?</b>
    <br>
    a. Name in Chinese (if applicable) and in English<br>
    b. Date of Birth (age 16 or above)<br>
    c. Number of identification document (for an applicant not holding a HKID card for registration, he/she will also need to provide statements to the effect that he/she (i) does not hold a HKID card; and (ii) has not used other identification documents for registration)<br>
    d. Copy of identification document<br>
    <br>

    <b>4. How can I upload the identification documents for real-name registration?</b>
    <br>
    Customer can complete the real-name registration through our website
    <a href="https://global.cmlink.com/store/realname">https://global.cmlink.com/store/realname</a> . You may take a picture of your original identification document by using a tablet/ smartphone equipped with camera, or upload image of your identification document to our website. Please note that we don’t accept black and white image.<br>
    <br>

    <b>5. What is the maximum registration quota of each user?</b>
    <br>
    Each user can register up to 10 cards.<br>
    <br>

    <b>6. How do I know if my registration has been successfully completed?</b>
    <br>
    We will issue email or SMS notification upon completion or failure of real-name registration. If you are unable to receive any SMS notification, please contact our CS for further inquiries.<br>
    <br>
    </p>
    </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FAQEnView'
})
</script>

<style scoped>
.main {
    width: 100%;
    padding: 5px;
    text-align: left;
  }
strong {
  font-size: 32px;
  font-weight: bold;
}
span {
  font-size: 18px;
  font-weight: bold;
  color:#8dc11e;
}
@media screen and (min-width: 600px) {
  .main {
    width: 80%;
    padding: 20px;
    text-align: left;
  }
}
</style>
