<template>
  <!-- 剩余流量 -->
  <div class="data-radius" :style="{borderRadius: '4px'}">
    <!-- <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="24">
        <el-tag type="warning" effect="light" > {{getI18n('套餐流量汇总', 'Package Data Summary')}} </el-tag>
      </el-col>
    </el-row> -->
    <template v-if="!isNullValue(props.subscriberQuota)">
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="24">
          <el-tag type="warning" effect="light" >
            {{getI18n('套餐类型', 'Type')}}:
            {{convertPackageType(props.subscriberQuota.type)}}
          </el-tag>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="24" class="line-center">
          <span class="font-red line-center-value">{{props.subscriberQuota.qtabalance}}</span>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="24" class="line-center">
          <el-text class="mx-1">{{getI18n('剩余高速流量', 'Remaining High-Speed Data')}}(M)</el-text>
        </el-col>
      </el-row>
      <el-divider />
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="11" class="line-center">
          <el-text class="mx-1 line-center-value" type="primary">{{props.subscriberQuota.qtavalue}}</el-text>
        </el-col>
        <el-col :span="2" class="line-center">
          <el-divider direction="vertical"/>
        </el-col>
        <el-col :span="11" class="line-center">
          <el-text class="mx-1 line-center-value" type="primary">{{props.subscriberQuota.refuelingTotal}}</el-text>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="11" class="line-center">
          <el-text class="mx-1">{{getI18n('套餐流量', 'Package Data')}}(M)</el-text>
        </el-col>
        <el-col :span="2" class="line-center">
          <el-divider direction="vertical"/>
        </el-col>
        <el-col :span="11" class="line-center">
          <el-text class="mx-1">{{getI18n('加油包流量', 'Add On Data')}}(M)</el-text>
        </el-col>
      </el-row>
      <!-- -->
      <!-- <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="11" class="line-center">
          <el-row class="card-row" :gutter="20" align="middle">
            <el-col :span="24" class="line-center">
            <el-text class="mx-1 line-center-value" type="primary">{{props.subscriberQuota.qtavalue}}</el-text>
          </el-col>
          <el-col :span="24" class="line-center">
            <el-text type="primary">{{getI18n('套餐流量', 'Package Data')}}(M)</el-text>
          </el-col>
          </el-row>
        </el-col>
        <el-col :span="2" class="line-center">
          <el-divider direction="vertical"/>
        </el-col>
        <el-col :span="11" class="line-center">
          <el-row class="card-row" :gutter="20" align="middle">
            <el-col :span="24" class="line-center">
            <el-text class="mx-1 line-center-value" type="primary">{{props.subscriberQuota.qtavalue}}</el-text>
          </el-col>
          <el-col :span="24" class="line-center">
            <el-text type="primary">{{getI18n('套餐流量', 'Package Data')}}(M)</el-text>
          </el-col>
          </el-row>
        </el-col>
      </el-row> -->
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { isNullValue, getI18n } from '../utils'
export default defineComponent({
  name: 'PacakgeDataCard'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  subscriberQuota: {
    type: Object,
    require: true
  }
})

function convertPackageType (packageType: string) {
  if (packageType === '1') {
    return getI18n('周期内限量', 'Data Restriction In Cycle')
  } else {
    return getI18n('单日限量', 'Data Restriction In Single Day')
  }
}
</script>

<style scoped>
.line-center {
  text-align: center;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.line-center-value {
  font-size: 32px;
}
.el-divider {
  margin: 0px;
}
</style>
