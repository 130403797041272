<template>
  <ImageWaterFall class="waterfall" :title="getI18n('安卓安装ESIM', 'Install ESIM on Adroid')" :imageList="imageList"></ImageWaterFall>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getI18n } from '../utils'
import ImageWaterFall from '../components/ImageWaterFall.vue'
export default defineComponent({
  name: 'AndroidInstallView'
})
</script>

<script lang="ts" setup>
const imageList = [
  {
    src: '/helper/android/1.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN1.png?x-image-process=style/imageslim',
    zhContent: '01.选择顶部的二维码选项',
    enContent: '01.Select the QR code option at the top'
  },
  {
    src: '/helper/android/2.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN2.png?x-image-process=style/imageslim',
    zhContent: '02.转到“设置 > 连接”',
    enContent: '02.Go to \'Settings > Connections\'.'
  },
  {
    src: '/helper/android/3.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN3.png?x-image-process=style/imageslim',
    zhContent: '03.点按“SIM 卡管理器”',
    enContent: '03.Tap \'SIM Card Manager\'.'
  },
  {
    src: '/helper/android/4.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN4.png?x-image-process=style/imageslim',
    zhContent: '04.选择“添加移动套餐”',
    enContent: '04.Select \'Add Mobile Package\'.'
  },
  {
    src: '/helper/android/5.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN5.png?x-image-process=style/imageslim',
    zhContent: '05.选择“扫描运营商二维码”',
    enContent: '05.Select \'Scan operator QR code\'.'
  },
  {
    src: '/helper/android/6.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN6.png?x-image-process=style/imageslim',
    zhContent: '06.将二维码置于引导线内进行扫描，在出现提示时输入确认码',
    enContent: '06.Scan the QR code by placing it inside the guide line and enter the confirmation code when prompted.'
  },
  {
    src: '/helper/android/7.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN7.png?x-image-process=style/imageslim',
    zhContent: '07.检测到 eSIM 套餐后，点按“添加”',
    enContent: '07.Tap \'Add\' when the eSIM Option is detected.'
  },
  {
    src: '/helper/android/8.png',
    // src: 'https://esimfile.cmlink.com/o2-resources/default-image/0/O2-PUB/install-page/Android/code-google-zh_CN8.png?x-image-process=style/imageslim',
    zhContent: '08.注册套餐后，选择“确定”开启套餐。激活 eSIM 后，您可以在 SIM 卡管理器中查看',
    enContent: '08.Once you have registered your plan, select OK to switch it on. activated eSIM, you can view the following in the SIM card manager'
  }
]
</script>
