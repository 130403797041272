<template>
  <div class="waterfall">
    <span class="title-font">{{title}}</span>
    <el-divider />
    <!-- 手机上 span 设置为 24 -->
    <!-- 小屏幕（比如平板） span 设置为 12 -->
    <!-- 中等屏幕（比如小型桌面） span 设置为 8 -->
    <!-- 大屏幕 span 设置为 6 -->
    <el-row :gutter="20">
      <el-col
        :span="6"
        :xs="24"
        :sm="12"
        :md="8"
        :lg="6"
        v-for="(item, index) in imageList" :key="index">
        <div class="waterfall-item">
          <img :src="item.src" class="image-style" />
          <span class="image-title">{{getI18n(item.zhContent, item.enContent)}}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { getI18n } from '../utils'
export default defineComponent({
  name: 'ImageWaterFall'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    require: true
  },
  imageList: {
    type: Object,
    require: true
  }
})
</script>

<style scoped>
.waterfall {
  width: 100%;
  padding: 5px;
}

.waterfall-item {
  margin-bottom: 20px;
  overflow: hidden;
}

.waterfall-item img {
  width: 100%;
  height: auto;
  display: block;
}
.title-font {
  font-weight: bold;
  font-size: 32px;
}
.image-title {
  font-weight: bold;
  font-size: 18px;
}

.image-style {
  width: 100%;
  margin-bottom: 10px;
}
@media screen and (min-width: 600px) {
  .waterfall {
    width: 80%;
    padding: 20px;
  }
}
</style>
