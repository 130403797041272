import { apiRootUrl } from '../config'
import store from '../store'

// fetch 获取数据
export async function apiFetchData (path: string) {
  const url = apiRootUrl + path
  console.log('apiFetchData url=', url)
  const response = await fetch(url)
  if (response.status === 302) {
    const redirectUrl = response.headers.get('Location')
    if (redirectUrl) {
      window.location.href = redirectUrl
      throw new Error('redirect a new page') // 抛出异常中断逻辑
    }
  }
  const jsonData = await response.json()
  return jsonData
}

// post 请求
export async function apiPostData (path: string, data: object) {
  const url = apiRootUrl + path
  console.log('apiFetchData url=', url)
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status === 302) {
        const redirectUrl = response.headers.get('Location')
        if (redirectUrl) {
          window.location.href = redirectUrl
          throw new Error('redirect a new page') // 抛出异常中断逻辑
        }
      }
      return response.json()
    })
    .then(function (data) {
      console.log('apiPostData' + data)
      return data
    })
    .catch(error => {
      console.log('----------------------------------------------------')
      console.log('Error:', error)
      console.log('****************************************************')
    })
}

export function getParameterByName (paramName: string): string {
  // 获取当前URL
  const url = new URL(window.location.href)
  // 获取查询参数
  const searchParams = new URLSearchParams(url.search)
  // 获取特定参数的值
  const value = searchParams.get(paramName)
  if (value === null) {
    return ''
  }
  return decodeURIComponent(value)
}

// 是否是空对象
// eslint-disable-next-line
export function isNullValue (value: any | null): boolean {
  return value === undefined || value === null
}

// 是否是空数组
// eslint-disable-next-line
export function isEmptyArray (arr: any | null): boolean {
  return arr === undefined || arr === null || arr.length === 0
}

// 设置cookie
export function setCookie (name: string, value: string, daysToLive: number) {
  let cookie = name + '=' + encodeURIComponent(value)
  if (typeof daysToLive === 'number') {
    cookie += '; max-age=' + (daysToLive * 24 * 60 * 60) // 将天数转换为秒数
  }
  document.cookie = cookie
}

// 获取cookie
export function getCookie (name: string) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length))
    }
  }
  return null
}

// 获取语言
export function getLanaguage () {
  if (store.state.lanaguage) {
    return store.state.lanaguage
  }
  let language = getParameterByName('language')
  if (!language) {
    language = navigator.language
  }
  store.state.lanaguage = language
  return store.state.lanaguage
}

// 是否是英文
export function isChinese (): boolean {
  const language = getLanaguage()
  return language.startsWith('zh')
}

// 获取实际显示内容
export function getI18n (zhContent: string, enContent: string): string {
  if (isChinese()) {
    return zhContent
  }
  return enContent
}

// 格式化时间显示
export function formatLocalTme (date: Date) {
// 获取转换后的本地时间的各个部分
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // 月份是从0开始的，并且我们想要两位数字
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  // 获取时区偏移量，并将其转换为GMT格式
  // const timezoneOffset = -date.getTimezoneOffset()
  // const gmtHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0')
  // const gmtMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0')
  // const gmtString = (timezoneOffset >= 0 ? 'GMT+' : 'GMT-') + gmtHours + gmtMinutes
  // 拼接成你想要的格式
  // const localTimeStr = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${gmtString}`
  //
  const localTimeStr = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

  return localTimeStr
}

// 获取本地时间
export function getLocalTime () {
  const now = new Date()
  return formatLocalTme(now)
}

export function convertTimestamp (timestamp: string): string {
  const year = parseInt(timestamp.slice(0, 4))
  const month = parseInt(timestamp.slice(4, 6))
  const day = parseInt(timestamp.slice(6, 8))
  const hours = parseInt(timestamp.slice(8, 10))
  const minutes = parseInt(timestamp.slice(10, 12))
  const seconds = parseInt(timestamp.slice(12, 14))

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

// 将utc时间转为本地时间
export function convertUTC2LocalTime (utcTimeStr: string) {
  if (!utcTimeStr) {
    return ''
  }
  // 创建一个Date对象，这里我们假设字符串是UTC时间，但在实际使用中，如果字符串没有时区信息，它会被解释为本地时间
  // 为了确保它被解释为UTC时间，你应该在字符串末尾添加'Z'，或者明确知道你的输入是UTC时间
  const date = new Date(utcTimeStr + 'Z') // 添加'Z'来表示UTC时间
  return formatLocalTme(date)
}

export function getCountryNameByMcc (mcc: string) {
  const mccMap: { [key: string]: [string, string] } = {
    412: ['阿富汗', 'Afghanistan'],
    276: ['阿尔巴尼亚', 'Albania'],
    603: ['阿尔及利亚', 'Algeria'],
    631: ['安哥拉', 'Angola'],
    365: ['安圭拉', 'Anguilla'],
    344: ['安提瓜岛和巴布达', 'Antigua and Barbuda'],
    722: ['阿根廷', 'Argentina'],
    283: ['亚美尼亚', 'Armenia'],
    363: ['阿鲁巴', 'Aruba'],
    505: ['澳大利亚', 'Australia'],
    232: ['奥地利', 'Austria'],
    400: ['阿塞拜疆', 'Azerbaijan'],
    364: ['巴哈马', 'Bahamas'],
    426: ['巴林', 'Bahrain'],
    470: ['孟加拉国', 'Bangladesh'],
    342: ['巴巴多斯', 'Barbados'],
    257: ['白俄罗斯', 'Belarus'],
    206: ['比利时', 'Belgium'],
    702: ['伯利兹', 'Belize'],
    616: ['贝宁', 'Benin'],
    402: ['不丹', 'Bhutan'],
    736: ['玻利维亚', 'Bolivia'],
    218: ['波黑', 'Bosnia and Herzegovina'],
    724: ['巴西', 'Brazil'],
    348: ['英属维尔京群岛', 'British Virgin Islands'],
    528: ['文莱', 'Brunei'],
    284: ['保加利亚', 'Bulgaria'],
    456: ['柬埔寨', 'Cambodia'],
    624: ['喀麦隆', 'Cameroon'],
    302: ['加拿大', 'Canada'],
    625: ['佛得角', 'Cape Verde'],
    346: ['开曼群岛', 'Cayman Islands'],
    623: ['中非共和国', 'Centrafrique'],
    730: ['智利', 'Chile'],
    460: ['中国', 'China'],
    732: ['哥伦比亚', 'Colombia'],
    712: ['哥斯达黎加', 'Costa Rica'],
    219: ['克罗地亚', 'Croatia'],
    368: ['古巴', 'Cuba'],
    362: ['库拉索岛和博奈尔岛', 'Curacao & Bonaire'],
    280: ['塞浦路斯', 'Cyprus'],
    230: ['捷克共和国', 'Czech Republic'],
    630: ['刚果民主共和国', 'Democratic Republic of Congo'],
    238: ['丹麦', 'Denmark'],
    366: ['多米尼克', 'Dominica'],
    370: ['多米尼加共和国', 'Dominican Republic'],
    740: ['厄瓜多尔', 'Ecuador'],
    602: ['埃及', 'Egypt'],
    706: ['萨尔瓦多', 'El Salvador'],
    248: ['爱沙尼亚', 'Estonia'],
    288: ['法罗群岛', 'Faroe Islands'],
    542: ['斐济', 'Fiji'],
    244: ['芬兰', 'Finland'],
    208: ['法国', 'France'],
    547: ['法属波利尼西亚', 'French Polynesia'],
    628: ['加蓬', 'Gabon'],
    607: ['冈比亚', 'Gambia'],
    282: ['格鲁吉亚', 'Georgia'],
    262: ['德国', 'Germany'],
    620: ['加纳', 'Ghana'],
    266: ['直布罗陀', 'Gibraltar'],
    202: ['希腊', 'Greece'],
    290: ['格陵兰', 'Greenland'],
    352: ['格林纳达', 'Grenada'],
    704: ['危地马拉', 'Guatemala'],
    738: ['圭亚那', 'Guyana'],
    372: ['海地', 'Haiti'],
    708: ['洪都拉斯', 'Honduras'],
    454: ['中国香港', 'Hong Kong, China'],
    216: ['匈牙利', 'Hungary'],
    274: ['冰岛', 'Iceland'],
    404: ['印度', 'India'],
    510: ['印度尼西亚', 'Indonesia'],
    432: ['伊朗', 'Iran'],
    272: ['爱尔兰', 'Ireland'],
    425: ['以色列', 'Israel'],
    222: ['意大利', 'Italy'],
    612: ['科特迪瓦（象牙海岸）', 'Ivory Coast (Cote d\'Ivoire)'],
    338: ['牙买加及加勒比海各国', 'Jamaica and Caribbean Countries'],
    440: ['日本', 'Japan'],
    416: ['约旦', 'Jordan'],
    401: ['哈萨克斯坦', 'Kazakhstan'],
    639: ['肯尼亚', 'Kenya'],
    212: ['科索沃', 'Kosovo'],
    419: ['科威特', 'Kuwait'],
    437: ['吉尔吉斯斯坦', 'Kyrgyzstan'],
    457: ['老挝', 'Laos'],
    247: ['拉脱维亚', 'Latvia'],
    618: ['利比里亚', 'Liberia'],
    246: ['立陶宛', 'Lithuania'],
    270: ['卢森堡', 'Luxembourg'],
    455: ['中国澳门', 'Macau, China'],
    294: ['马其顿', 'Macedonia'],
    646: ['马达加斯加', 'Madagascar'],
    650: ['马拉维', 'Malawi'],
    502: ['马来西亚', 'Malaysia'],
    472: ['马尔代夫', 'Maldives'],
    610: ['马里', 'Mali'],
    278: ['马耳他', 'Malta'],
    340: ['马提尼岛', 'Martinique'],
    617: ['毛里求斯', 'Mauritius'],
    334: ['墨西哥', 'Mexico'],
    259: ['摩尔多瓦', 'Moldova'],
    428: ['蒙古', 'Mongolia'],
    297: ['黑山', 'Montenegro'],
    354: ['蒙特塞拉特岛', 'Montserrat'],
    604: ['摩洛哥', 'Morocco'],
    643: ['莫桑比克', 'Mozambique'],
    414: ['缅甸', 'Myanmar'],
    429: ['尼泊尔', 'Nepal'],
    204: ['荷兰', 'Netherlands'],
    530: ['新西兰', 'New Zealand'],
    710: ['尼加拉瓜', 'Nicaragua'],
    614: ['尼日尔', 'Niger'],
    242: ['挪威', 'Norway'],
    422: ['阿曼', 'Oman'],
    410: ['巴基斯坦', 'Pakistan'],
    714: ['巴拿马', 'Panama'],
    537: ['巴布亚新几内亚', 'Papua New Guinea'],
    744: ['巴拉圭', 'Paraguay'],
    716: ['秘鲁', 'Peru'],
    515: ['菲律宾', 'Philippines'],
    260: ['波兰', 'Poland'],
    268: ['葡萄牙', 'Portugal'],
    330: ['波多黎各', 'Puerto Rico'],
    427: ['卡塔尔', 'Qatar'],
    647: ['留尼汪岛', 'Reunion'],
    226: ['罗马尼亚', 'Romania'],
    250: ['俄罗斯', 'Russia'],
    635: ['卢旺达', 'Rwanda'],
    420: ['沙特', 'Saudi Arabia'],
    220: ['塞尔维亚', 'Serbia'],
    633: ['塞舌尔', 'Seychelles'],
    619: ['塞拉利昂', 'Sierra Leone'],
    525: ['新加坡', 'Singapore'],
    231: ['斯洛伐克', 'Slovakia'],
    293: ['斯洛文尼亚', 'Slovenia'],
    655: ['南非', 'South Africa'],
    450: ['韩国', 'South Korea'],
    214: ['西班牙', 'Spain'],
    413: ['斯里兰卡', 'Sri Lanka'],
    356: ['圣基茨和尼维斯', 'St. Kitts/Nevis'],
    358: ['圣卢西亚', 'St. Lucia'],
    360: ['圣文森特', 'St. Vincent'],
    634: ['苏丹', 'Sudan'],
    746: ['苏里南', 'Suriname'],
    653: ['斯威士兰', 'Swaziland'],
    240: ['瑞典', 'Sweden'],
    228: ['瑞士', 'Switzerland'],
    466: ['中国台湾', 'Taiwan, China'],
    436: ['塔吉克斯坦', 'Tajikistan'],
    640: ['坦桑尼亚', 'Tanzania'],
    622: ['乍得', 'Tchad'],
    520: ['泰国', 'Thailand'],
    539: ['汤加', 'Tonga'],
    374: ['特立尼达和多巴哥', 'Trinidad and Tobago'],
    605: ['突尼斯', 'Tunisia'],
    286: ['土耳其', 'Turkey'],
    376: ['特克斯和凯克斯群岛', 'Turks and Caicos'],
    424: ['阿联酋', 'UAE'],
    641: ['乌干达', 'Uganda'],
    234: ['英国', 'UK'],
    255: ['乌克兰', 'Ukraine'],
    748: ['乌拉圭', 'Uruguay'],
    310: ['美国', 'USA'],
    434: ['乌兹别克斯坦', 'Uzbekistan'],
    541: ['瓦努阿图', 'Vanuatu'],
    734: ['委內瑞拉', 'Venezuela'],
    452: ['越南', 'Vietnam'],
    421: ['也门', 'Yemen'],
    645: ['赞比亚', 'Zambia'],
    648: ['津巴布韦', 'Zimbabwe']
  }

  if (mcc in mccMap) {
    if (isChinese()) {
      return mccMap[mcc][0]
    } else {
      return mccMap[mcc][1]
    }
  } else {
    return mcc // 若未找到，返回原始 mcc
  }
}
