<template>
  <div class="data-radius" :style="{borderRadius: '4px'}">
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="24">
        <el-text class="mx-1 font-red">{{getI18n("SIM卡的有效期是2年，每次使用都会顺延2年", "Extend SIM card validity of 2 years when use any data or order new package once.")}}</el-text>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="9" class="col-title">
        <el-text class="mx-1">{{getI18n('SIM卡过期时间', 'SIM Expire Time')}}:</el-text>
      </el-col>
      <el-col :span="15">
        <el-text class="mx-1" type="primary">{{getSimExpire()}}</el-text>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="9" class="col-title">
        <el-text class="mx-1">{{getI18n('实名结果', 'Real Name Result')}}:</el-text>
      </el-col>
      <el-col :span="15">
        <el-text class="mx-1" type="primary">{{getRealNameResult()}}</el-text>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { getI18n, convertTimestamp } from '../utils'
export default defineComponent({
  name: 'IccidCard'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  iccidInfoResp: {
    type: Object,
    require: true
  }
})
function getSimExpire () {
  return convertTimestamp(props.iccidInfoResp.himsis[0].expireTime)
}
function getRealNameResult () {
  const authStatus = props.iccidInfoResp.himsis[0].realRuleList[0].authStatus
  if (authStatus === null) {
    return getI18n('未实名', 'Not registered')
  } else if (authStatus === '1') {
    return getI18n('代认证', 'To be certified')
  } else if (authStatus === '2') {
    return getI18n('认证中', 'Verifying')
  } else if (authStatus === '3') {
    return getI18n('已认证', 'Certified')
  } else if (authStatus === '4') {
    return getI18n('认证失败', 'Authentication failed')
  } else if (authStatus === '5') {
    return getI18n('实名过期', 'The certificate has expired')
  }
}
</script>

<style scoped>
.line-center {
  text-align: center;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.line-center-value {
  font-size: 32px;
}
</style>
