<template>
  <ImageWaterFall class="waterfall" :title="getI18n('IOS安装ESIM', 'Install ESIM on Adroid')" :imageList="imageList"></ImageWaterFall>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getI18n } from '../utils'
import ImageWaterFall from '../components/ImageWaterFall.vue'
export default defineComponent({
  name: 'AndroidInstallView'
})
</script>

<script lang="ts" setup>
const imageList = [
  {
    src: '/helper/apple/1.png',
    zhContent: '01.前往‘设置’页面，点击‘蜂窝网络’。',
    enContent: '01.Go to the \'Settings\' page and click on\' Cellular \'.'
  },
  {
    src: '/helper/apple/2.png',
    zhContent: '02.点击‘添加eSIM’ 或 ‘设置蜂窝号码’',
    enContent: '02.Click \'Add eSIM\' or \'Set Cellular Number\'.'
  },
  {
    src: '/helper/apple/3.png',
    zhContent: '03.点击‘使用二维码’',
    enContent: '03.Click \'Use QR Code\'.'
  },
  {
    src: '/helper/apple/4.png',
    zhContent: '04.扫描CMLink eSIM App上提供的二维码，然后轻点两次‘**继续**’后稍等片刻。您的eSIM将连接到网络，这可能需要几分钟，然后轻点‘完成’。',
    enContent: '04.Scan the QR code provided on the CMLink eSIM App, tap \'Continue\' twice and wait for a moment. Your eSIM will connect to the network, which may take a few minutes, and then tap \'Done\'.'
  },
  {
    src: '/helper/apple/5.png',
    zhContent: '05.激活eSIM，点击‘继续’开始激活。',
    enContent: '05.Activate eSIM, click \'Continue\' to activate.'
  },
  {
    src: '/helper/apple/6.png',
    zhContent: '06.eSIM安装成功！，在出现提示时输入确认码',
    enContent: '06.eSIM installation is successful!'
  }
]
</script>
