<template>
  <div class="main">
    <strong>{{ getI18n('适用机型', 'Compatible Devices') }}</strong>
    <p>● {{ getI18n('海外版iPhone', 'iPhones purchased from countries outside China') }}<br>
    &nbsp;&nbsp;&nbsp;&nbsp;■ iPhone XR, iPhone XS, iPhone XS Max, iPhone 11, iPhone Pro, iPhone 11 Pro Max, iPhone SE2020, iPhone 12, iPhone 12 Pro, iPhone 12 Pro Max, iPhone 12 Mini, iPhone 13, iPhone 13 Pro, iPhone 13 Pro Max, iPhone 13 Mini, iPhone 14, iPhone 14 Pro, iPhone 14 Pro Max, iPhone 14 Plus, iPhone 15, iPhone 15 Pro, iPhone 15 Pro Max, iPhone 15 Plus<br>
    ● {{ getI18n('港澳版iPhone', 'iPhones purchased in Hong Kong/Macao SAR') }}<br>
    &nbsp;&nbsp;&nbsp;&nbsp;■ iPhone SE2020, iPhone XS, iPhone 12 mini, iPhone 13 mini, iPhone SE (2nd generation or after)<br>
    ● Samsung Galaxy S23<br>
    ● Samsung Galaxy S23+<br>
    ● Samsung Galaxy S23 Ultra<br>
    ● Samsung Galaxy S22<br>
    ● Samsung Galaxy S22+<br>
    ● Samsung Galaxy S22 Ultra<br>
    ● Samsung Galaxy S21+ 5G<br>
    ● Samsung Galaxy S21 Ultra 5G<br>
    ● Samsung Galaxy Note 20 FE 5G<br>
    ● Samsung Galaxy Note 20 FE<br>
    ● Samsung Galaxy Note 20 Ultra, Ultra 5G<br>
    ● Samsung Note 20+<br>
    ● Samsung Fold LTE model<br>
    ● Samsung Galaxy Z Flip<br>
    ● Samsung Galaxy S20, s20+ and S20 Ultra (Single SIM Nano-SIM and/or eSIM models)<br>
    ● Samsung Galaxy Z Fold2 5G<br>
    ● Samsung Galaxy Fold<br>
    ● Samsung Galaxy Z Flip3 5G<br>
    ● Samsung Galaxy Z Flip4<br>
    ● Samsung Galaxy Z Flip5 5G<br>
    ● Google Pixel Fold<br>
    ● Google Pixel 8 Pro<br>
    ● Google Pixel 8<br>
    ● Google Pixel 7 Pro<br>
    ● Google Pixel 7<br>
    ● Google Pixel 6 Pro<br>
    ● Google Pixel 6a<br>
    ● Google Pixel 6<br>
    ● Google Pixel 5a<br>
    ● Google Pixel 5<br>
    ● Google Pixel 4a<br>
    ● Google Pixel 4<br>
    ● Google Pixel 3 &amp; 3XL ( Limited support )<br>
    ● Huawei P40 and P40 Pro (purchased outside mainland China)<br>
    ● Oppo Find X3 Pro (purchased outside mainland China)<br>
    ● Motorola Razr (2nd Gen)<br>
    ● Motorola Razr (1st Gen)<br>
    </p>
    <p class="font-red">{{ getI18n('内地版iPhone不支持 eSIM。', 'iPhones purchased from mainland China do not support eSIM.') }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { getI18n } from '../utils'
export default defineComponent({
  name: 'SupportDeviceView'
})
</script>

<script lang="ts" setup>
// setp 内必须由内容，否则被编译没了 getI18n 函数找不到
onMounted(async () => {
  console.log('enter')
})
</script>
<style scoped>
.main {
  width: 100%;
  padding: 5px;
  text-align: left;
}
strong {
  font-size: 32px;
  font-weight: bold;
}
span {
  font-size: 18px;
  font-weight: bold;
  color:#8dc11e;
}
@media screen and (min-width: 600px) {
  .main {
    width: 80%;
    padding: 20px;
    text-align: left;
  }
}
</style>
